// jshint ignore: start
import React, { useState } from "react"
import { makeStyles } from '@material-ui/core/styles';
import { Typography, List, ListItem } from '@material-ui/core';
import { SocialIcon } from 'react-social-icons';
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { useIntl, Link, changeLocale } from "gatsby-plugin-intl"
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: 'auto',
        backgroundColor: "lightgray"
    },
    container: {
        color: '#000',
        textAlign: 'center',
        backgroundColor: '#f6f6f6',
        padding: '1.5em 0 1em 0'
    },
    aitem: {
        padding: 5,
        whiteSpace: 'nowrap',
        color: 'rgba(255, 255, 255, .75)',
        fontSize: '.9em',
        display: 'inline-block',
        width: 'auto'
    },
    link: {
        color: '#333232',
        textDecoration: 'none',
        '&:hover': {
            color: '#5a5858',
            borderBottom: '1px solid #5a5858',
        }
    },
    list: {
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
        maxWidth: 500,
        margin: '0 auto',
        justifyContent: 'space-around',
        listStyle: 'none'
    },
    listItem: {
        display: 'inline-block',
        textAlign: 'center',
        marginBottom: 0
    },
    social: {
        margin: 4
    }
}));

const Footer = ({ children }) => {
    const classes = useStyles();
    const intl = useIntl();
    const [lang, setLang] = useState(intl.locale);
    
    const data = useStaticQuery(graphql`
        query {
            footerloguinho: file(relativePath: { eq: "site-logo.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: FIXED, width: 116, placeholder: NONE)
                }
            }
            livro: file(relativePath: { eq: "livro.png" }) {
                childImageSharp {
                    gatsbyImageData(layout: FIXED, width: 116, placeholder: NONE)
                }
            }
        }
    `)

    const handleChangeLanguage = (event) => {
        changeLocale(event.target.value)
        setLang(event.target.value);
    };

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <a href="/">
                    <GatsbyImage alt="" loading="eager" style={{margin: '0 auto'}} image={data.footerloguinho.childImageSharp.gatsbyImageData} />
                </a>
                <List className={classes.list}>
                    <ListItem className={classes.listItem}>
                        <Link className={classes.link} to="/privacy"><Typography component="span">{intl.formatMessage({ id: "footer.link_privacy" })}</Typography></Link>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <Link className={classes.link} to="/terms"><Typography component="span">{intl.formatMessage({ id: "footer.link_terms" })}</Typography></Link>
                    </ListItem>
                </List>
                <div style={{padding: '20px 0'}}>
                    <SocialIcon className={classes.social} url="https://www.facebook.com/Raitec-3D-1953463698212683" />
                    <a role="button" aria-label={intl.formatMessage({ id: "footer.book" })} href="https://www.livroreclamacoes.pt/inicio"><GatsbyImage alt="" loading="eager" style={{margin: '0 auto'}} image={data.livro.childImageSharp.gatsbyImageData} /></a>
                </div>
                <Typography variant="caption" style={{marginBottom: 20}} component="p">{intl.formatMessage({ id: "footer.powered" })}</Typography>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Select
                        value={lang}
                        onChange={handleChangeLanguage}
                        displayEmpty
                        inputProps={{ 'aria-label': intl.formatMessage({ id: "footer.language_change" }) }}
                    >
                        <MenuItem value="pt" >{intl.formatMessage({ id: "footer.language_pt" })}</MenuItem>
                        <MenuItem value="en" >{intl.formatMessage({ id: "footer.language_en" })}</MenuItem>
                    </Select>
                    <FormHelperText>{intl.formatMessage({ id: "footer.language_change" })}</FormHelperText>
                </FormControl>
            </div>
        </div>
    )
}

export default Footer