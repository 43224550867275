/*eslint semi: ["warn", "never"]*/
import React, {useRef} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Footer from "./footer"
import Header from "./header"
import "./layout.css"
import { useLocation, navigate } from '@reach/router'
const { removeTrailingSlash } = require(`../tools/gatsby-node-helpers`)


  const useComponentWillMount = func => {
  const willMount = useRef(true)

  if (willMount.current) {
    func()
  }

    willMount.current = false
  }


const Layout = ({ children }) => {
  const location = useLocation()
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

    useComponentWillMount(() =>  {
      const isLang = location.pathname.match(/\/en\/|\/pt\//g)
      if (isLang && !location.pathname.endsWith("/")) {
        if (location.pathname.endsWith("index.html")) {
          location.pathname.replace("index.html", "")
        } else {
          navigate(removeTrailingSlash(location.pathname) + "/")
        }
      }
    }
  )

  return (
    <div>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main>
        {children}
      </main>
      <Footer/>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
